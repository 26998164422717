import React from "react"
import { graphql } from 'gatsby'

import SEO from "../components/layout/seo"
import '../components/pages/info/info.scss'
// import WebGL from '../components/webGLstuff/webGL'

import Transition from '../components/transition/transition'

export default (props) => {

  const { data } = props;
  const info = data.wpgraphql.pages.nodes[0];

  return (
    <div className="page__info">
    
      <SEO title="Info" />
      
      <Transition>
         <div className="webgl">
          {/*<WebGL /> */}
        </div>
        <div className="info">
          <div className="info__intro">
            <div dangerouslySetInnerHTML={{__html: info.infoPage.info }} />
            <br />
            
          </div>
          
          <div className="info__details">
            <div className="info__details__section">
              <div>About</div>
              <div dangerouslySetInnerHTML={{__html: info.infoPage.details }} />
              <br />
            </div>
            {
              info.infoPage.team.map((member, i) => {
                return(
                  <div className="info__details__section" key={i}>
                    <div dangerouslySetInnerHTML={{__html: member.name}}/>
                    <div dangerouslySetInnerHTML={{__html: `${member.info}<br/>`}}/>
                  </div>
                )
              })
            }
            <div>
              <div className="info__details__section">
                <div  dangerouslySetInnerHTML={{__html: "Contact"}} />
                <div>
                {
                    info.infoPage.team.map((member, i) => {
                      return(
                        <div key={i}>
                          <a href={`mailto:${member.email}`} >{member.name}</a>
                        </div>
                      )
                    })
                  } 
                </div>
                </div>
              </div>
            </div>  
          </div>  
          {
            info.imprint ?
            <div>
              <p dangerouslySetInnerHTML={{__html: info.imprint.label}}/>
              <p dangerouslySetInnerHTML={{__html: info.imprint.text}}/>
            </div>
            : false
          }
      </Transition>
    </div>
  )
}

export const infoQuery = graphql`
  query infoQuery {
    wpgraphql{
      posts {
        nodes{
          title
          slug
          date
        }
      }
      pages(where: {name: "info"}) {
        nodes{
          title
          slug
          infoPage {
            info
            details
            imprint {
              ... on WPGraphQL_Page_Infopage_Imprint_Imprint{
                label
                text
              }
            }
            team {
              email
              info
              name
            }
          }
        }
      }
    }
  }
`
// export const infoQuery = graphql`
//   query infoQuery {
//     wordpressPage(slug: {eq: "info"}) {
//       title
//       slug
//       path
//       infoPage {
//         info
//         imprint_page {
//           id
//           label
//           text
//         }
//         team {
//           email
//           info
//           name
//         }
//       }
//       childWordPressinfoPageLink {
//         id
//       }
//       childWordPressinfoPageImprint {
//         text
//         label
//       }
//     }
//   }
// `